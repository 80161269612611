<template>
  <div class="email">
    <Header></Header>
    <div class="email-banner">

      <div class="banner-img-font">
        <div class="email-banner-img-font-border">
          <strong>电子邮箱验证</strong>
          <ul>
            <li>验证电子邮箱以后，方便您更全面的接收平台的消息通知，</li>
            <li>或者您的任务进度需求。</li>
          </ul>
        </div>
      </div>

      <div class="email-banner-border">
        <div class="email-banner-border-center">
          <img v-lazy="userInfo.avatar" alt=""/>
          <div class="email-banner-border-center-list">
            <li>我们已将邮箱验证链接发送至您的邮箱，{{hideStar( this.userInfo.email )}}</li>
            <li>请您登录邮箱点击验证</li>
          </div>

          <button type="button" @click="goToEmaulAddress"> 前往 {{ emailName }}</button>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Banner from "@/components/Banner";
import {bindEmail} from "@/api/index";

export default {
  components: {
    Banner,
  },
  data() {
    return {
      userInfo: {
        avatar: '',
        email: ''
      },
      emailName: "",
      hashEmails: {
        '126.com': 'http://mail.126.com',
        '163.com': 'http://mail.163.com',
        '139.com': 'https://mail.10086.cn/',
        'qq.com': 'http://mail.qq.com',
        'gmail.com': 'http://mail.google.com',
        'sina.com': 'http://mail.sina.com.cn',
      }
    }
  },
  mounted() {
    this.userInfo.avatar = this.$root.user.avatar
    var cemail = localStorage.getItem('current_email')
    if (cemail != '' && cemail != null) {
      this.userInfo.email = cemail
      this.hasName( cemail )
    }else{
      this.$router.go(-1)
    }
    localStorage.removeItem( 'current_email' )
  },
  methods: {
    async toBindEmail() {
      var toUrl = '/email/result'
      if( this.$route.query.redirect_url != '' && typeof( this.$route.query.redirect_url ) != 'undefined' ){
        toUrl = '/email/result?redirect_url=' + this.$route.query.redirect_url
      }
      const res = await bindEmail( { email: this.userInfo.email } )
      if (res.statusCode == 200) {
        localStorage.removeItem( 'current_email' )
        this.$router.push( { path: toUrl } )
      }else{
        this.$notify({
          type: "error",
          title: "提示",
          message: res.message
        })
      }
    },
    hideStar(email) {
      if (String(email).indexOf('@') > 0) {
        let str = email.split('@'),
            _s = ''
        if (str[0].length > 3) {
          for (let i = 0; i < str[0].length - 3; i++) {
            _s += '*'
          }
        }
        var new_email = str[0].substr(0, 3) + _s + '@' + str[1]
      }
      return new_email
    },
    goToEmaulAddress(){
      localStorage.removeItem( 'current_email' )
      let address = this.goToEmail(this.userInfo.email)
      if(address){
        window.open( address )
      }else{
        let _email=this.userInfo.email.split("@")[1].toLowerCase()
        window.open( 'https://www.baidu.com/s?wd='+_email )
      }
    },
    goToEmail(email){
      //获取注册邮箱@后面的地址
      let _email=email.split("@")[1].toLowerCase()
      if(this.hashEmails.hasOwnProperty(_email)){
        return this.hashEmails[_email]
      }else{
        return false
      }
    },
    hasName(email) {
      //获取注册邮箱@后面的地址
      let _email=email.split("@")[1].toLowerCase()
      if(this.hashEmails.hasOwnProperty(_email)){
        this.emailName = this.hashEmails[_email]
      }else{
        return false
      }
    }
  }
};
</script>

<style lang="less" scoped>
.email {
  background-color: #f8f8f8;
}
.banner-img-font {
  background-image: url(~@/assets/banner/底图.jpg);
  width: 100%;
  height: 400px;
  background-size: cover;

  div {
    position: relative;
    width: 800px;
    margin: 0 auto;
    top: 30px;
    color: #fff;

    strong {
      font-size: 18px;
      display: block;
      margin-bottom: 23px;
    }

    li {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
}


.email-banner-border {
  width: 800px;
  height: 434px;
  background-color: #fff;
  margin: 0 auto;
  margin-top: -250px;

  .email-banner-border-center {
    //width: 315px;
    margin: 0 auto;
    position: relative;
    top: 100px;
    text-align: center;

    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    .email-banner-border-center-list {
      margin: 40px 0;

      li {
        font-size: 12px;
        line-height: 20px;
        color: #575757;
        margin-bottom: 12px;
      }
    }

    button {
      width: 300px;
      line-height: 36px;
      background-color: #00a2e6;
      font-size: 16px;
      color: #fff;
    }
  }
}
</style>
